export interface CreditCard {
  companyName: string,
  creditCardGuid: string,
  creditCardType: string,
  firstSixDigits: string,
  lastFourDigits: string,
  expirationDate: string,
}

export interface OneTimeTokenResponse {
  nexioOneTimeTokenIframe: {
    token: string
  }
}

export interface CreditCardsForAssociateResponse {
  nexioCreditCardsForAssociate: CreditCard[]
}

export interface NexioPostTransactionResponse {
  nexioPostTransaction: {
    value: string
  }
}

export interface NexioToken {
  firstSix: string,
  lastFour: string,
  cardType: string,
  token: string
}

export interface NexioCard {
  cardHolderName: string
  cardType: string,
  expirationMonth: string,
  expirationYear: string
}

export interface NexioTokenSavedResponse {
  token: NexioToken,
  card: NexioCard,
  data: any //uneeded info at the moment
}

export interface NexioFormValidation {
  isFormValid: boolean
}

export interface NexioFormError {
  error: number,
  message: string
}

export const convertNexioToQSCard = (nexioCard: NexioCard, nexioToken: NexioToken): CreditCard => {
  return {
    companyName: 'Nexio (USD)',
    creditCardGuid: nexioToken.token,
    creditCardType: nexioToken.cardType,
    firstSixDigits: nexioToken.firstSix,
    lastFourDigits: nexioToken.lastFour,
    expirationDate: `${nexioCard.expirationYear}-${nexioCard.expirationMonth}-01`
  }
}

export interface NexioPostTransData {
  magentoOrderId: string,
  creditCardGuid: string
}