import React, { useEffect, useRef, useState } from 'react'

// components
import { Seo, Loading, PageHeader } from '../components'
import { Primary as PrimaryButton } from '../components/Buttons'

//styles
import { ErrorMsg } from '../styles/LoginPage.styles'
import {
  IframeForm,
  AddCardContainer,
  SaveCheckbox,
} from '../styles/AddCard.styles'

import environment from '../utils/environment'
import { QService } from '../services/q-services'
import { navigate } from 'gatsby'

import {
  convertNexioToQSCard,
  NexioFormError,
  NexioFormValidation,
  NexioTokenSavedResponse,
} from '../types/PaymentsTypes'
import { useStore } from '../context/StoreContext'
import { useAuthContext } from '../context/AuthContext'
import { usePrismic } from '../context/PrismicContext'

const SUBMIT_EVENT = 'submit'
const VALIDATION_EVENT = 'formValidations'
const CARD_SAVED_EVENT = 'cardSaved'
const ERROR_EVENT = 'error'

const AddCardPage = () => {
  const [nexioIframeUrl, setIframeUrl] = useState('')
  const [saveToFile, setSaveToFile] = useState(true)
  const [isFormClean, setIsFormClean] = useState(true)
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [error, setError] = useState('')
  const { addCardToFile } = useStore()
  const { qUser, setQUser } = useAuthContext()
  const nexioIframe = useRef(null)

  const {
    prismicData: { prismicAddCardPage },
  } = usePrismic()

  const {
    update_payment_info,
    payment_information,
    form_invalid_error,
    loading: loadingText,
    save_card_for_future_purchases,
    use_card,
  } = prismicAddCardPage

  const saveCard = async ({ card, token }: NexioTokenSavedResponse) => {
    setSaveToFile(saveToFile => {
      setQUser(qUSer => {
        const qsCard = convertNexioToQSCard(card, token, qUser.associateId)
        addCardToFile(qsCard, saveToFile)
        navigate('/cart')
        return qUser
      })
      return saveToFile
    })
  }

  useEffect(() => {
    const getOneTimeToken = async () => {
      if (nexioIframeUrl) return // used for hot reloading (don't want to spam the sandbox for iframe tokens)
      setLoading(true)
      const token = await QService.getNexioOneTimeCode()
      const url = `${environment.NEXIO_URL}/pay/v3/saveCard?token=${token}`
      setIframeUrl(url)
    }

    getOneTimeToken()

    window.addEventListener('message', ({ origin, data: eventData }) => {
      if (origin === environment.NEXIO_URL) {
        const { event, data: nexioData } = eventData
        switch (event) {
          case CARD_SAVED_EVENT:
            saveCard(nexioData as NexioTokenSavedResponse)
            break
          case SUBMIT_EVENT:
            setSaving(true)
            break
          case VALIDATION_EVENT:
            const { isFormValid } = nexioData as NexioFormValidation
            if (!isFormValid) setError(form_invalid_error[0].text)
            else setError('')
            break
          case ERROR_EVENT:
            // handle errors from Nexio form submission
            const { message } = nexioData as NexioFormError
            setSaving(false)
            setError(message)
            break
        }
      }
    })
  }, [])

  // Use our own button to tell iframe to submit contents
  const submitForm = e => {
    e.preventDefault()
    setIsFormClean(false)
    if (nexioIframe.current.contentWindow) {
      nexioIframe.current.contentWindow.postMessage('posted', nexioIframeUrl) // submit iframe
    }
    return false
  }

  return (
    <>
      {loading && <Loading loading={loading} message={loadingText[0].text} />}
      <Seo title={update_payment_info[0].text} />
      <PageHeader url="/payment-info">{payment_information[0].text}</PageHeader>
      <AddCardContainer>
        <IframeForm
          src={nexioIframeUrl}
          ref={nexioIframe}
          onLoad={() => {
            setLoading(false)
          }}
        />
        {!isFormClean && <ErrorMsg>{error}</ErrorMsg>}
        <SaveCheckbox
          name="saveToFile"
          label={save_card_for_future_purchases[0].text}
          checked={saveToFile}
          onChange={() => setSaveToFile(!saveToFile)}
        />
        <PrimaryButton
          style={{
            width: '100%',
          }}
          loading={saving}
          onClick={submitForm}
        >
          {' '}
          {use_card[0].text}{' '}
        </PrimaryButton>
      </AddCardContainer>
    </>
  )
}

export default AddCardPage
